/**
 * Created by Jordy on 19/07/2017.
 */
$(function(){

    $('.autochecksetsearch input[type=checkbox]').on({
        change: function (e) {
            //e.preventDefault();
            //e.stopPropagation();

            var name = $(this).attr('name').split('[')[0];

            var selectedValue = [];

            $(this).parents('.filter__options').find(':checked').each(function() {
                selectedValue.push($(this).val());
            });

            if(selectedValue.length === 0) {
                selectedValue = null;
            }

            controlParams(name, selectedValue);
            
            return false;
        }
    });

    $('body').on('click', 'a.filter__tag', function(e) {
        var name = $(this).data('name');
        var removeId = $(this).data('id');

        if(name && removeId) {

            e.preventDefault();
            e.stopPropagation();

            var val = removeFromURLParameter(name, removeId);

            var fieldName = 'FilterForm_' + name + '_' + removeId;

            $('#' + fieldName).prop('checked', false);

            controlParams(name, val);
        }
    });

    $('#FilterForm').submit(function(e) {
        e.preventDefault();
        e.stopPropagation();

        return false;
    });
});

function controlParams(key, val)
{
    $('.loader').show();
    $('.filter__options').addClass('is--loading');

    var path;

    if(val) {
        path = document.location.pathname + addUrlParam(document.location.search, key, val);
        if(key !== 'start') {
            path = removeURLParameter(path, 'start');
        }
    } else {
        path = removeURLParameter(window.location.href, key);
    }

    ajaxCall(path);
}

function addUrlParam(search, key, val){
    var newParam = key + '=' + val,
        params = '?' + newParam;

    // If the "search" string exists, then build params from it
    if (search) {
        // Try to replace an existance instance
        params = search.replace(new RegExp('([?&])' + key + '[^&]*'), '$1' + newParam);

        // If nothing was replaced, then add the new param to the end
        // If it isnt already in the params
        if (params === search) {
            if(params.indexOf(newParam) === -1) {
                params += '&' + newParam;
            }
        }
    }

    return params;
}

function removeFromURLParameter(name, remove)
{
    var currentValue = getUrlParameter(name);

    if(currentValue == null) {
        return null;
    }

    var array = currentValue.split(','),
        index = array.indexOf(remove.toString());

    if(index > -1) {
        array.splice(index, 1);
    }

    if(array.length > 0) {
        return array;
    } else {
        return null;
    }
}

function getUrlParameter(sParam, linkPart) {
    if(linkPart == null) {
        linkPart = window.location.search.substring(1);
    }

    var sPageURL = decodeURIComponent(linkPart),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? true : sParameterName[1];
        }
    }
}

function removeURLParameter(url, parameter) {
    //prefer to use l.search if you have a location/link object
    var urlparts = url.split('?');
    if (urlparts.length>=2) {

        var prefix = encodeURIComponent(parameter) + '=';
        var pars = urlparts[1].split(/[&;]/g);

        //reverse iteration as may be destructive
        for (var i = pars.length; i-- > 0;) {
            //idiom for string.startsWith
            if (pars[i].lastIndexOf(prefix, 0) !== -1) {
                pars.splice(i, 1);
            }
        }

        url = urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : "");
        return url;
    } else {
        return url;
    }
}


if(!!('ontouchstart' in window)){//check for touch device
    $('.filter').on('click', function(){
        $('.filter').removeClass('is--hover');
        $(this).addClass('is--hover');
    });

    $(window).click(function() {
        $('.filter').removeClass('is--hover');
    });
    $('.filter').click(function(event){
        event.stopPropagation();
    });
}
else{
    $('.filter').on('mouseenter', function(){
        $(this).addClass('is--hover');
    });

    $('.filter').on('mouseleave', function(){
        $(this).removeClass('is--hover');
    });
}

function ajaxCall(path)
{
    window.history.pushState("", "", path);

    $.ajax({
        url: path,
        cache: false,
        success: function(data) {
            data = JSON.parse(data);

            $('.filter-holder').html(data.Filters);
            $('.result-holder').html(data.Results);
        },
        complete: function() {
            $('.loader').hide();
            $('.filter__options').removeClass('is--loading');
            $('.filter').removeClass('is--hover');
        }
    });
}